/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import CustomerSearch from '../components/Customer/CustomerSearch';
import CustomerDetails from '../components/Customer/CustomerDetails';
import { AgreementProvider } from '../components/Agreement/AgreementContext';
import AgreementSearch from '../components/Agreement/AgreementSearch/AgreementSearch';
import Agreementdetails from '../components/Agreement/AgreementDetails/AgreementDetails';
import { CustomerContextProvider } from '../components/Customer/CustomerDependencies/CustomerContext';
import { InventorySearchContextProvider } from '../context/Inventory/InventorySearchContext';
import { InventorySearch } from '../components/Inventory/InventorySearch';
import LocationSearch from '../components/Location/LocationSearch';
import StoreDetails from '../components/Location/StoreDetails';
import { InventoryDetailsContextProvider } from '../context/Inventory/InventoryDetailsContext';
import { InventoryDetails } from '../components/Inventory/InventoryDetails';
import { LegalHoldProvider } from '../components/LegalHold/LegalHoldContext';
import LegalHold from '../components/LegalHold/LegalHold';
import { LocationContextProvider } from '../components/Location/LocationDependencies/LocationContext';
import MenuPermissions from '../components/MenuPermissions/MenuPermissions';
import { AdminProvider } from '../components/MenuPermissions/AdminContext';
import { AppConfigurationContextProvider } from '../context/AppConfigurations/AppConfigurationContext';
import { AppConfiguration } from '../components/AppConfigurations/AppConfigurations';
import ResetPin from '../components/ResetPIN/ResetPin';
import { ResetPinProvider } from '../components/ResetPIN/ResetPinContext';


export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>

    {/* <PermissionsProvider> */}
    <UserFetcher />
    <div data-testid={routesTestId}>
      {/* <Switch> */}
      <CustomerContextProvider>
        <Route exact path={AppRoute.Customer}>
          <CustomerSearch />
        </Route>
        <Route exact path={AppRoute.CustomerDetailsWithTab}>
          <CustomerDetails />
        </Route>
      </CustomerContextProvider>

      <AgreementProvider>
        <Route exact path={AppRoute.AgreementSearch}>
          <AgreementSearch />
        </Route>
        <Route exact path={AppRoute.AgreementDetails}>
          <Agreementdetails />
        </Route>
      </AgreementProvider>

      <LocationContextProvider>
        <Route exact path={AppRoute.LocationSearch}>
          <LocationSearch />
        </Route>
        <Route exact path={AppRoute.StoreDetails}>
          <StoreDetails />
        </Route>
      </LocationContextProvider>

      <InventorySearchContextProvider>
        <Route exact path={AppRoute.Inventory}>
          <InventorySearch />
        </Route>
        <InventoryDetailsContextProvider>
          <Route exact path={AppRoute.InventoryDetails}>
            <InventoryDetails />
          </Route>
        </InventoryDetailsContextProvider>
      </InventorySearchContextProvider>

      <LegalHoldProvider>
        <Route exact path={AppRoute.LegalHoldSearch}>
          <LegalHold />
        </Route>
      </LegalHoldProvider>

      <AdminProvider>
        <Route exact path={AppRoute.menuPermissions}>
          <MenuPermissions />
        </Route>
      </AdminProvider>

      <ResetPinProvider>
        <Route exact path={AppRoute.resetPin}>
          <ResetPin />
        </Route>
      </ResetPinProvider>

      <AppConfigurationContextProvider>
        <Route exact path={AppRoute.appConfiguration}>
          <AppConfiguration />
        </Route>
      </AppConfigurationContextProvider>
      {/* </Switch> */}
    </div>
    {/* </PermissionsProvider> */}

  </UserProvider>
);
