/*eslint-disable */
import { client, clientCall } from './client';
// import { appConfig } from '../config/app-config';
import { appConfigURL }  from '../config/app-config';
import { CancelToken } from 'axios';
import { ProductPermissions } from '../constants/constants';
import { User } from '../types/types';

const appConfig : any = appConfigURL();
const CustomerBaseURL = appConfig.apiUrls.customer;
const AccountMgmtURL = appConfig.apiUrls.am;
const PaymentURL = appConfig.apiUrls.payment;
const OpsMgmt = appConfig.apiUrls.micro;
const AgreementBaseUrl = appConfig.apiUrls.agreement;
const taxMgmtBaseUrl = appConfig.apiUrls.tax;
const InventoryBaseUrl=appConfig.apiUrls.inventory;
const storemgmtBaseUrl=appConfig.apiUrls.storemgmt;
const dashBoardURL = appConfig.apiUrls.dashboard;
const pricingURL = appConfig.apiUrls.pricing;

export const getCurrentUser = (): Promise<User> =>
  clientCall('users/current', { method: 'GET' }, appConfig.apiUrls.container);

export const getMenuConfigurationDetails = async (body: any) => {
  return await clientCall(
    `menu-and-stores`,
    { method: "POST", payload: body },
    appConfig.apiUrls.menu,
  );
};

export const getUserID = async () => {
  return await clientCall(
    `user/current`,
    { method: "GET" },
    appConfig.apiUrls.menu
  );
};

export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    appConfig.apiUrls.container
  );
  
  export const getGovtIdType = async (storeNumber : string) =>{
    try {
      return await clientCall(
        `customer/customerinfo-options/${storeNumber}`,
        { method: 'GET' },
        CustomerBaseURL
      )
    }
    catch (e: any) {
      return null
    }
  }
  
  
  export const getSearchResults = async (payload: any) => {
    try {
      let response =  await clientCall(
        `customer/validate`,
        { method: 'POST', payload: payload },
        CustomerBaseURL  
      )
      return response;
    }
    catch (e: any) {
      return null;
    }
  }

  export const GetLegalCustomer = async (payload: any) => {
    try {
      return await clientCall(
        `legal/summary`,
        { method: 'POST', payload: payload },
        OpsMgmt  
      )
    }
    catch (e: any) {
      return null;
    }
  }
  
  export const GetCustomerIdByGCId = async (gcId : string) => {
    try{
      return await clientCall(
        `customer/${gcId}/detail`,
        { method: 'GET' },
        CustomerBaseURL
      )
    }
    catch(e:any){
      return null
    }
  }

  export const getDropDownData = async (storeNumber: string) => {
    try {
      return await clientCall(
        `customer/customerinfo-options/${storeNumber}`,
        { method: 'GET' },
        CustomerBaseURL
      );
    } catch (e: any) {
      return null;
    }
  };
  
  export const GetCustomerDetails = async (customerId : any) => {
    try{
      return await clientCall(
        `customer/${customerId}`,
        {method: 'PUT',  payload : { storeNumber: '00000' }},
        CustomerBaseURL
      )
    }
    catch(e:any){
      return null
    }
  }
  
  export const GetCustomerAgreements = async (customerId : any, type : string) => {
    try{
      return await clientCall(
        `customer/${customerId}/agreements?inActive=${type}`,
        {method: 'GET'},
        CustomerBaseURL 
      )
    }
    catch(e:any){
      return null
    }
  }
  
  export const GetCustomerPaymentHistory = async (customerId : any, limit : any, offset : any) => {
    try{
      return await clientCall(
        `payment1/details/history/${customerId}?type=recp&limit=${limit}&offset=${offset}`,
        { method: 'GET'},
        PaymentURL
      )
    }
    catch(e:any){
      return null
    }
  }
  
  export const GetCustomerWorkHistory = async (customerId : any, limit : any, offset : any) => {
    try{
      return await clientCall(
        `activity-logs?limit=${limit}&offset=${offset}`,
        { method: 'POST', payload : {storeNumber : sessionStorage.getItem('storeNumber'), customer : [customerId] }},
        AccountMgmtURL  
      )
    }
    catch(e:any){
      return null
    }
  }

  export const updateTaxExemptCustomer = async (payload : any) => {
    try{
      const data = await clientCall(
        `managetaxexempt`,
        { method: 'POST', payload : payload},
        taxMgmtBaseUrl
      )
      return data;
    }
    catch(e:any){
      return e;
    }
  }
  
  export const CheckCustomerLegalHold = async (customerId : any) => {
    try{
      return await clientCall(
        `customer/${customerId}/legal-hold`,
        { method: 'GET'},
        CustomerBaseURL 
      )
    }
    catch(e:any){
      return null
    }
  }
  
  
   export const GetPaymentReceiptById = async (receiptId : any, type : any) => {
    try{
      return await clientCall(
        `documents/${receiptId}?type=${type}&version=ALL`,
        { method: 'GET'},
        AccountMgmtURL 
      )
    }
    catch(e:any){
      return null
    }
  }
  
  export const getAgreementReceiptById = async (payload : any) => {
    try{
      return await clientCall(
        `agreement/document/print`,
        { method: 'POST', payload : payload },
        AgreementBaseUrl,
      );
    }
    catch(e:any){
      return null
    }
  }

  export const getAgreementData = async (payload: any) => {
    try{
      return await clientCall(
        `agreement/summary`,
        { method: 'POST', payload: payload },
        OpsMgmt
      );
    }
    catch(e:any){
      return null
    }
  };

  export const getAgreementInfo = async (AgreementId: any) => {
    try{
    return await clientCall(
      `agreement/${AgreementId}`,
      { method: 'GET' },
      AgreementBaseUrl
    );
    } 
    catch(e: any) {
      return null;
    }
  };

  export const getPrintDocument = async (payload: any) => {
    try{
    return await clientCall(
      `agreement/document/print`,
      { method: 'POST', payload: payload },
      AgreementBaseUrl
    );
    }
    catch(e: any) {
      return null;
    }
  };
  
  export const GetAgreementactivity = async (agreementId: string) => {
    try {
      return await clientCall(
        `agreement/activity/${agreementId}`,
        { method: 'GET' },
        AgreementBaseUrl
      );
    } catch (err: any) {
      return null;
    }
  };

  export const GetCustomerCoverage = async (
    customerId: any,
    storeNumber: any
  ) => {
    try {
      return await clientCall(
        `customer/${customerId}/${storeNumber}`,
        { method: 'PUT' },
        CustomerBaseURL
      );
    } catch (e: any) {
      return null;
    }
  };

  export const getInventoryDetails = async (inventoryNumber: any) => {
    return await clientCall(
      `inventory/details/${inventoryNumber}`,
      { method: 'GET' },
      InventoryBaseUrl,
    );
  };
  
  
  export const getActivities = async (inventoryNumber: any, setNumber: any) => {
    return await clientCall(
      `inventory/activities/${inventoryNumber}?setNumber=${setNumber}`,
      { method: 'GET' },
      InventoryBaseUrl
    );
  };
  
  export const getInventoryInformation = async (inventoryNumber: any) => {
    return await clientCall(
      `inventory/${inventoryNumber}`,
      { method: 'GET' },
      InventoryBaseUrl
    );
  };
  
  export const getInventoryAgreementHistory = async (inventoryNumber: any) => {
    return await clientCall(
      `inventory/agreements/${inventoryNumber}`,
      { method: 'GET' },
      InventoryBaseUrl
    );
  };
  
  export const getInventoryPaymentHistory = async (
    offset: any,
    inventoryId: any
  ) => {
    try {
      return await clientCall(
        `payment1/details/history/00000?type=agr&inventoryId=${inventoryId}&limit=10&offset=${offset}`,
        {
          method: 'GET',
        },
        PaymentURL
      );
    } catch (err: any) {
      return null;
    }
  };
  
  export const getSearchParams = async () => {
    return await clientCall(
      `inventory/search-params/""`,
      { method: 'GET' },
      InventoryBaseUrl
    );
  };
  
  export const getRadiusStore = async (storeNumber: any) => {
    return await clientCall(
      `radius-stores/${storeNumber}?storeNumber=${storeNumber}`,
      { method: 'GET' },
      storemgmtBaseUrl
    );
  };
  
  export const getInventorySearch = async (payload: any) => {
  
    return await clientCall(
      'inventory/search',
      { method: 'POST', payload: payload },
      InventoryBaseUrl,
    );
  };

  export const getStoreDetails = async (payload: any) => {
    try {
      return await clientCall(
        'store/lookup',
        { method: 'POST', payload: payload },
        dashBoardURL
      );
    } catch (e: any) {
      return null;
    }
  };

  export const getpaymentHistory = async (customer_id: string, type: string, offset: any, agreementId: any) => {
    try {
      return await clientCall(
        `payment1/details/history/${customer_id}?type=${type}&agreementId=${agreementId}&limit=10&offset=${offset}`,
        {
          method: "GET",
        },
        PaymentURL
      );
    } catch (err: any) {
      return null;
    }
  };
  
  export const getDocument = async (
    documentId: string,
    typeId?: string
  ) => {
    return await clientCall(
      `documents/${documentId}?type=${typeId}`,
      { method: 'GET' },
      AccountMgmtURL
    );
  };

  export const getmenuandstore = async (parameters: any) => {
    return await clientCall(
      `menu-and-stores`,
      { method: 'POST', payload: parameters },
      appConfig.apiUrls.menu
    );
  };
  
export const fetchOrganizationDetails = async (Payload: any) => {
  try {
    return await clientCall(
      `hierarchy`,
      { method: "POST", payload: Payload },
      pricingURL,
    );
  } catch (e: any) {
    return null;
  }
};
  
  export const manageMenu = async (payload: any) => {
    try {
      return await clientCall(
        'managemenu',
        { method: 'POST', payload: payload },
        OpsMgmt
      );
    } catch (e: any) {
      return null;
    }
  };
  
  export const fetchConfigReference = async (payload: any) => {
    try {
      return await clientCall(
        'configreference',
        { method: 'POST', payload: payload },
        OpsMgmt
      );
    } catch (e: any) {
      return null;
    }
  };
  
  export const getPinDetails = async (payload: any) => {
    try {
      return await clientCall(
        'coworker',
        { method: 'POST', payload: payload },
        storemgmtBaseUrl
      );
    } catch (e: any) {
      return null;
    }
  };
  
  export const resetPin = async (payload: any) => {
    try {
      return await clientCall(
        'pin/manage',
        { method: 'POST', payload: payload },
        dashBoardURL
      );
    } catch (e: any) {
      return null;
    }
  };

  export const getOrgRulesSummary= async (payload:any)=>{
    try {

      return await clientCall(
        'store-details',
        { method: 'POST', payload: payload },
        storemgmtBaseUrl
      );

    } catch (e: any) {
      return null;
    }
  }

  export const manageOrganizationRule= async (payload:any)=>{
    try {
      return await clientCall(
        'manageconfig',
        { method: 'POST', payload: payload },
        OpsMgmt
      );      
    } catch (e: any) {
      return null;
    }
  }