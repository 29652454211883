import {
  GetLegalCustomer,
  getCurrentUser,
  getMenuConfigurationDetails,
} from '../../../api/user';
import {
  Agreement,
  EmployerReference,
  LandlordReference,
  PaymentHistory,
  PersonalReference,
  TaxExempt,
  Vehicle,
  WorkHistory,
  customerDetails,
  dropdown,
} from '../../../constants/customerDetailsInterface';
import {
  formateDateFromDateAndTime,
  getCstFormateDate,
  getFormatedDate,
} from '../../../utils/utils';
import { getLegalHoldReasons } from '../../Agreement/commonFunctions';
import { getLegalData } from './LegalDetailsHelper';

const CLOSED_STATUSES = [
  'PIF',
  'SAC',
  'EPO',
  'CO',
  'ARTS',
  'RTV',
  'PRELIMCANC',
  'VOID',
];

export function handleInActiveAgreements(
  inactiveAgreementDetails: any,
  setResponseStatus: any,
  setInactiveAgreements: any
) {
  inactiveAgreementDetails = inactiveAgreementDetails?.data;
  if (inactiveAgreementDetails?.agreement?.length > 0) {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      inactiveAgreementTabStatus: 200,
    }));
    const agreementData = getAgreementData(
      inactiveAgreementDetails?.agreement,
      1
    );
    if (agreementData?.length > 0) {
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        inactiveAgreementTabStatus: 200,
      }));
    } else {
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        inactiveAgreementTabStatus: 204,
      }));
    }
    setInactiveAgreements(agreementData);
  } else {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      inactiveAgreementTabStatus: 204,
    }));
    setInactiveAgreements([]);
  }
}

export async function handleActiveAgreements(
  activeAgreementDetails: any,
  setResponseStatus: any,
  setActiveAgreements: any
) {
  activeAgreementDetails = activeAgreementDetails?.data;
  if (activeAgreementDetails?.agreement && activeAgreementDetails != '') {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      activeAgreementTabStatus: 200,
    }));
    let agreementData = await getAgreementData(
      activeAgreementDetails?.agreement,
      0
    );
    const clubAgreementData = await getClubData(
      activeAgreementDetails?.clubInfoResponse,
      activeAgreementDetails?.customerId
    );
    agreementData = [...agreementData, ...clubAgreementData];
    setActiveAgreements(agreementData);
    // const clubAgreementData = getClubData(
    //   activeAgreementDetails?.clubInfoResponse,
    //   activeAgreementDetails?.customerId
    // );
    // setActiveAgreements((pre: any) => [...pre, ...clubAgreementData]);
  } else {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      activeAgreementTabStatus: 204,
    }));
    setActiveAgreements([]);
  }
}

function getClubData(clubAgreement: any, customerId: any) {
  const agreementData: Agreement[] = [];
  clubAgreement.forEach((element: any) => {
    const data: Agreement = {
      agreementNumber: element?.agreementNumber,
      storeNumber: element?.storeNumber,
      route: element?.route,
      customers: [{ priority: '1', customerId: customerId }],
      agreementDescription: 'Club',
      agreementOpenDate: element?.clubBeginDate,
      currentDueDate: element?.currentDueDate,
      currentDayslate: element?.currentDayslate,
      actualRate: element?.actualRate,
      actualTerm: element?.actualTerm,
      rentPaid: element?.rentPaid,
      closeDate: element?.closeDate,
      agreementStatusReason: element?.agreementStatusReason,
      agreementId: element?.agreementId,
      autoPay: element?.autoPay,
      agreementType: element?.agreementType,
    };
    agreementData.push(data);
  });
  return agreementData;
}

function getAgreementData(agreementData: any, type?: any) {
  const agreementDatas: Agreement[] = [];
  const inActiveData: Agreement[] = [];
  agreementData.forEach((element: any) => {
    const data: Agreement = {
      agreementNumber: element?.agreementNumber,
      storeNumber: element?.storeNumber,
      route: element?.route,
      customers: element?.customers,
      agreementDescription: element?.agreementDescription,
      agreementOpenDate: element?.agreementOpenDate,
      currentDueDate: element?.currentDueDate,
      currentDayslate: element?.currentDayslate,
      actualRate: element?.actualRate,
      actualTerm: element?.actualTerm,
      rentPaid: element?.rentPaid,
      closeDate: element?.closeDate,
      agreementStatusReason: element?.agreementStatusReason,
      agreementId: element?.agreementId,
      agreementType: element?.agreementTypeRefCode,
      autoPay: element?.autoPay,
    };
    CLOSED_STATUSES.includes(element.agreementRefCode)
      ? inActiveData.push(data)
      : agreementDatas.push(data);
  });
  if (type == 1) {
    return inActiveData;
  } else {
    return agreementDatas;
  }
}

export function handleReferenceDetails(
  referenceDetails: any,
  setResponseStatus: any,
  setReferenceTableBody: any
) {
  if (referenceDetails) {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      referenceTabStatus: 200,
    }));
    const residenceObject: PersonalReference[] = [];
    referenceDetails.forEach((element: any) => {
      const data = {
        active: element?.active,
        relationshipTypeDesc: element?.relationshipTypeDesc,
        firstName: element?.firstName,
        lastName: element?.lastName,
        doNotCall: element?.doNotCall,
        phoneNumber: element?.phoneNumber,
        bestTimeToCallDesc: element?.bestTimeToCallDesc,
      };
      residenceObject.push(data);
    });
    setReferenceTableBody(residenceObject);
  } else {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      referenceTabStatus: 204,
    }));
    setReferenceTableBody([]);
  }
}

export function handleEmploymentDetails(
  employeeDetails: any,
  setResponseStatus: any,
  setEmployementDetails: any
) {
  if (employeeDetails) {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      employmentTabStatus: 200,
    }));
    const employmentDetails: EmployerReference[] = [];
    employeeDetails.forEach((element: any) => {
      const data = getEmployeeData(element);
      employmentDetails.push(data);
    });

    setEmployementDetails(employmentDetails);
  } else {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      employmentTabStatus: 204,
    }));
    setEmployementDetails([]);
  }
}

function getEmployeeData(element: any) {
  const data: EmployerReference = {
    employerName: element?.employerName ? element?.employerName : '-',
    supervisorFirstName: element?.supervisorFirstName
      ? element?.supervisorLastName
        ? element?.supervisorLastName + ' ' + element?.supervisorFirstName
        : ''
      : '',
    supervisorLastName: element?.supervisorLastName,
    doNotCall: element?.doNotCall,
    employerPhoneNumber: element?.employerPhoneNumber,
    doNotVisit: element?.doNotVisit,
    addressLine1: element?.addressLine1
      ? element?.addressLine2
        ? element?.addressLine1 + ' ' + element?.addressLine2
        : ''
      : '',
    addressLine2: element?.addressLine2,
    active: element?.active,
    jobTitle: element?.jobTitle,
    workStartTime: element?.workStartTime,
    workEndTime: element?.workEndTime,
    employerPayscheduleDesc: element?.employerPayscheduleDesc,
    daysPaidDesc: element?.daysPaidDesc,
    takeHomePay: element?.takeHomePay,
    lengthOfEmploymentYears: element?.lengthOfEmploymentYears,
  };
  return data;
}

export function handleResidenceDetails(
  residence: any,
  setResponseStatus: any,
  setResidenceTableBody: any
) {
  if (residence) {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      residenceTabStatus: 200,
    }));
    const updatedResidenceArray: LandlordReference[] = [];

    residence.forEach((element: any) => {
      const data: LandlordReference = {
        leaseCompanyName: element?.leaseCompanyName,
        monthlyRent: element?.monthlyRent,
        landlordFirstName: element?.landlordFirstName,
        landlordLastName: element?.landlordLastName,
        phoneNumber: element?.phoneNumber,
        bestTimeToCallDesc: element?.bestTimeToCallDesc,
        addressLine1: element?.addressLine1,
        addressLine2: element?.addressLine2,
        active: element?.active,
        leaseHolderName: element?.leaseHolderName,
        lengthOfLease: element?.leaseHolderName
          ? element?.leaseLengthYears
          : '',
        lengthOfResidency: element?.moveInDate
          ? getLengthOfResidence(element.moveInDate)
          : '',
      };
      updatedResidenceArray.push(data);
    });
    setResidenceTableBody(updatedResidenceArray);
  } else {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      residenceTabStatus: 204,
    }));
    setResidenceTableBody([]);
  }
}

export function handleVehicleDetails(
  vehicleInfo: any,
  setResponseStatus: any,
  setVehicleTableBody: any
) {
  if (vehicleInfo) {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      vehicleTabStatus: 200,
    }));
    const vehicleArray: Vehicle[] = [];

    vehicleInfo.forEach((element: any) => {
      const data: Vehicle = {
        utilities: element?.utilities,
        vehicleMake: element?.vehicleMake,
        vehicleColor: element?.vehicleColor,
        vehicleTagNumber: element?.vehicleTagNumber,
        vehRegState: element?.vehRegState,
        phoneInWhose: element?.phoneInWhose,
        vehFinancedFrom: element?.vehFinancedFrom,
        autoPayment: element?.autoPayment,
      };
      vehicleArray.push(data);
    });
    setVehicleTableBody(vehicleArray);
  } else {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      vehicleTabStatus: 204,
    }));
    setVehicleTableBody([]);
  }
}

export function handleTaxExemptDetails(
  taxExamptData: any,
  setResponseStatus: any,
  setTaxExamptTableBody: any
) {
  if (taxExamptData) {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      taxExemptTabStatus: 200,
    }));
    const taxExempt: TaxExempt[] = [];
    taxExamptData.forEach((item: any) => {
      const data: TaxExempt = {
        customerTaxExemptId: item?.customerTaxExemptId,
        federalTaxId: item?.federalTaxId,
        taxExemptStatusDesc: item?.taxExemptStatusDesc,
        taxExemptStatus: item?.taxExemptStatus,
        taxExemptStatusDate: item?.taxExemptStatusDate,
        submittedDate: item?.submittedDate,
        expirationDate: item?.expirationDate,
        taxExemptStatusReasonDesc: item?.taxExemptStatusReasonDesc,
      };
      taxExempt.push(data);
    });
    setTaxExamptTableBody(taxExempt);
  } else {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      taxExemptTabStatus: 204,
    }));
    setTaxExamptTableBody([]);
  }
}

export function getCategory(details: any, legalCheck: any) {
  const category = [];
  if (details?.skip == 'Y') {
    category.push('Skip');
  }
  if (details?.stolen == 'Y') {
    category.push('Stolen');
  }
  if (details?.hard == 'Y') {
    category.push('Hard');
  }
  if (legalCheck) {
    category.push('Legal');
  }

  return category.join(' - ');
}

function formCustomerData(
  details: any,
  category: any,
  phoneNumber: any,
  primaryAddress: any,
  mailingAddress: any
) {
  const customerData: customerDetails = {
    status: 200,
    name: details?.firstName
      ? details?.lastName
        ? details?.firstName + ' ' + details?.lastName
        : details?.firstName
      : '',
    coCustomerName: details?.coCustomer
      ? details?.coCustomer == 'N'
        ? '-'
        : details?.coCustomer
      : '-',
    coCustomerId: details?.coCustomerId ? details.coCustomerId : null,
    creationDate: details?.customerSinceDate
      ? getFormatedDate(details.customerSinceDate)
      : '-',
    residenceType: details?.residenceType ? details?.residenceType : '-',
    contact: getCustomerContact(
      details,
      primaryAddress,
      mailingAddress,
      category,
      phoneNumber
    ),
    personal: getPersonalData(details),
    preferences: getCustomerPreferences(details),
  };

  return customerData;
}
function getCustomerContact(
  details: any,
  primaryAddress: any,
  mailingAddress: any,
  category: any,
  phoneNumber: any
) {
  return {
    primaryAddress: primaryAddress ? primaryAddress : '-',
    mailingAddress: mailingAddress ? mailingAddress : '-',
    phone: phoneNumber
      ? `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6)}`
      : '-',
    emailAddress: details?.emailAddress ? details.emailAddress : '-',
    category: category,
  };
}

function getPersonalData(details: any) {
  return {
    dateOfBirth: details?.dateOfBirth
      ? `${details?.dateOfBirth.slice(-5).split('-').join('/')}/****`
      : '-',
    socialSecurity: details?.taxId ? details.taxId : '-',
    govIdType: details?.governmentIdTypeDesc
      ? details.governmentIdTypeDesc
      : '-',
    govId: details?.governmentId ? details.governmentId : '-',
    issuedState: details?.governmentIdIssuedState
      ? details.governmentIdIssuedState
      : '-',
  };
}

function getCustomerPreferences(details: any) {
  return {
    preferredLanguage: details?.preferredLanguageDesc
      ? details.preferredLanguageDesc
      : '-',
    paymentRemainderCalls:
      details?.paymentReminderCallsAllowed == 'Y' ? 'Yes' : 'Opt out',
    preferredMethodOfContact: details?.preferredContactMethodDesc
      ? details.preferredContactMethodDesc
      : '-',
    phoneSolicitation:
      details?.phoneSolicitationAllowed == 'Y' ? 'Allowed' : 'Not Allowed',
    mailSolicitation:
      details?.mailSolicitationAllowed == 'Y' ? 'Allowed' : 'Not Allowed',
  };
}

export function handleWorkHistoryDetails(
  workHistory: any,
  setResponseStatus: any,
  setWorkHistoryTableBody: any,
  workHistoryTableBody: any
) {
  workHistory = workHistory?.data;
  if (Object.keys(workHistory)?.length != 0) {
    if (workHistory?.accountActivities) {
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        workHistoryTabStatus: 200,
      }));
      const workHistoryDetails: WorkHistory[] = [];
      workHistory?.accountActivities.forEach((item: any) => {
        const data = getWorkHistoryData(item);
        workHistoryDetails.push(data);
      });
      setWorkHistoryTableBody((prev: any) => [...prev, ...workHistoryDetails]);
    } else {
      checkWorkHistoryDetails(
        workHistoryTableBody,
        setResponseStatus
        // setWorkHistoryTableBody
      );
    }
  } else {
    checkWorkHistoryDetails(
      workHistoryTableBody,
      setResponseStatus
      // setWorkHistoryTableBody
    );
  }
}

export function formatePhoneNumber(number: any) {
  if (number.length == 10) {
    number = number.replace(/(\d{1,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
  } else {
    number = number.replace(/\D/g, '');
  }
  return number;
}

function checkWorkHistoryDetails(
  workHistoryTableBody: any,
  setResponseStatus: any
  // setWorkHistoryTableBody: any
) {
  if (workHistoryTableBody.length > 0) {
    setResponseStatus((prevStatus: any) => ({
      ...prevStatus,
      workHistoryTabStatus: 200,
    }));
  }
  // else {
  // setResponseStatus((prevStatus: any) => ({
  //   ...prevStatus,
  //   workHistoryTabStatus: 204,
  // }));
  // setWorkHistoryTableBody([]);
  // }
}

export function getWorkHistoryData(item: any) {
  const data: WorkHistory = {
    storeNumber: item?.storeNumber,
    phoneNumberDialed: item?.phoneNumberDialed
      ? `(${item?.phoneNumberDialed.slice(
          0,
          3
        )}) ${item?.phoneNumberDialed.slice(
          3,
          6
        )}-${item?.phoneNumberDialed.slice(6)}`
      : '-',
    personalReference: item?.personalReference?.description,
    callResultType: item?.callResultType
      ? item?.callResultType?.description
      : item?.accountActivityType?.description
      ? item?.accountActivityType?.description
      : '-',
    commitmentStatus: item?.commitment?.commitmentStatus?.description,
    notes: item?.notes,
    activityDate: item?.activityDate
      ? getCstFormateDate(item?.activityDate)
      : item?.activityDate,
    pastDueDate: item?.pastDueDate,
    daysLate: item?.daysLate,
    coWorker: item?.coWorker?.firstName
      ? item?.coWorker?.lastName
        ? item?.coWorker?.lastName + ' ' + item?.coWorker?.firstName
        : item?.coWorker?.firstName
      : '-',
    callsToday: item?.callsToday,
  };
  return data;
}

export async function handlePaymentHistoryDetails(
  paymentHistory: any,
  setResponseStatus: any,
  setPaymentHistoryTableBody: any,
  setWorkHistoryTableBody: any
) {
  const paymentData: any = paymentHistory?.data?.value?.customerPaymentHistory;
  if (paymentData) {
    if (Object.keys(paymentData)?.length == 0) {
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        paymentHistoryTabStatus: 204,
      }));
      setPaymentHistoryTableBody([]);
    } else {
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        paymentHistoryTabStatus: 200,
        workHistoryTabStatus: 200,
      }));
      const paymentDetails: PaymentHistory[] = [];

      paymentData.forEach((element: any) => {
        const data: PaymentHistory = {
          receiptId: element?.receiptId,
          store: element?.store,
          paymentOrigin: element?.paymentOrigin,
          tenderType: element?.tenderType,
          daysExt: element?.daysExt,
          daysLate: element?.daysLate,
          datePaid: formate(element),
          pastDueDate: element?.pastDueDate,
          nextDueDate: element?.nextDueDate,
          actualTerm: element?.actualTerm,
          rentPaid: element?.rentPaid,
          closeDate: element?.closeDate,
          agreementStatusReason: element?.agreementStatusReason,
          agreementId: element?.agreementId,
          oldDueDate: element?.oldDueDate,
          netRent: element?.netRent,
          clubAmount: element?.clubAmount,
          ldw: element?.ldw,
          lateFee: element?.lateFee,
          discount: element?.discount,
          other: element?.other,
          tax: element?.tax,
          totalPayment: element?.totalPayment
            ? formatePayment(element?.totalPayment)
            : '-',
          nsf: element?.nsf,
          cc4Digit: element?.cc4Digit,
          coWorker: element?.coWorker,
        };
        paymentDetails.push(data);
      });
      setPaymentHistoryTableBody(paymentDetails);
      const workData: WorkHistory[] = await formPaymentHistoryToWorkHistoryData(
        paymentDetails
      );
      setWorkHistoryTableBody(workData);
    }
  } else {
    if (paymentHistory?.data?.value?.code == 400) {
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        paymentHistoryTabStatus: 400,
      }));
    } else {
      setResponseStatus((prevStatus: any) => ({
        ...prevStatus,
        paymentHistoryTabStatus: 204,
      }));
      setPaymentHistoryTableBody([]);
    }
  }
}

export function formate(element: any) {
  return element?.datePaid
    ? element?.timePaid
      ? formateDateFromDateAndTime(element.datePaid + ' ' + element.timePaid)
      : element.datePaid
    : '-';
}

export function formPaymentHistoryToWorkHistoryData(
  paymentHistoryTableBody: any
) {
  const array: any = [];
  paymentHistoryTableBody.forEach((element: any) => {
    const data = {
      activityDate: formate(element),
      daysLate: element?.daysLate,
      notes: element?.totalPayment,
      phoneNumberDialed: null,
      storeNumber: element.store,
      pastDueDate: element.pastDueDate,
      callResultType: 'Payment',
      personalReference: null,
      coWorker: element?.coWorker,
    };
    array.push(data);
  });
  return array;
}

export function formatePayment(total: string) {
  if (parseFloat(total) >= 0) {
    return '$' + total;
  } else {
    return `($${total})`;
  }
}

export function handleCustomerData(
  details: any,
  setPhonePopupDetailsBody: any,
  legalCheck: any,
  setCaterogy: any
) {
  let primaryAddress = '';
  let mailingAddress = '';
  let phoneNumber = '';
  for (let i = 0; i < details?.addresses?.length; i++) {
    if (details?.addresses[i]?.addressType == 'PRIM') {
      primaryAddress = getAddress(details, i);
    }
    if (details?.addresses[i].addressTypeDesc == 'Mailing Address') {
      mailingAddress = getAddress(details, i);
    }
  }
  for (let i = 0; i < details.phones?.length; i++) {
    if (details.phones[i].primary == 'Y') {
      phoneNumber = details.phones[i].phoneNumber;
    }
  }

  if (details.phones) {
    setPhonePopupDetailsBody(details.phones);
  } else {
    setPhonePopupDetailsBody([]);
  }

  function getAddress(details: any, i: number) {
    return (
      details?.addresses[i].addressLine1 +
        (details?.addresses[i].addressLine2
          ? `, ${details?.addresses[i].addressLine2}`
          : '') ?? ''
    );
  }

  const category = getCategory(details, legalCheck);
  setCaterogy(category);

  const customerData: customerDetails = formCustomerData(
    details,
    category,
    phoneNumber,
    primaryAddress,
    mailingAddress
  );

  return customerData;
}

function getLengthOfResidence(date: any) {
  const currentDate: any = new Date();
  const startResidenceDate: any = new Date(date);
  const timeDifference: any = currentDate - startResidenceDate;
  const years: any = timeDifference / (365.25 * 24 * 60 * 60 * 1000);
  const roundedYears: any = Math.floor(years);
  return roundedYears.toString() + ' Years';
}

export function formateTaxExemptDropDown(dropDown: any) {
  const ddData = dropDown?.data?.taxExemptStatusReason;

  let values: dropdown[] = [];
  ddData.map((data: any, index: number) => {
    index === 0
      ? values.push(
          { label: 'Select', value: '0' },
          { label: data.description, value: data.referenceCode }
        )
      : values.push({
          label: data.description,
          value: data.referenceCode,
        });
  });

  values = values.sort((a: any, b: any) =>
    a['value'].localeCompare(b['value'])
  );

  return values;
}

export function formateLegalHoldDropDown(dropDown: any, identifier?: any) {
  let ddData;
  const unHoldRefCode = ['HE', 'LAD', 'RLH'];
  if (identifier) {
    const refCode = ['E', 'F', 'G', 'H', 'PLAF', 'PLAC', 'DNC', 'NFB'];
    ddData = dropDown?.data?.resLegalHoldList;
    const legalValues: dropdown[] = [{ label: 'Select', value: '0' }];
    ddData.map((data: any) => {
      if (refCode.includes(data.referenceCode)) {
        legalValues.push({
          label: getLegalHoldReasons(data.description),
          value: data.referenceCode,
        });
      }
    });
    return legalValues;
  } else {
    ddData = dropDown?.data?.legalResolvedReason;
    const legalValues: dropdown[] = [{ label: 'Select', value: '0' }];
    ddData.map((data: any) => {
      if (!unHoldRefCode.includes(data.referenceCode)) {
        legalValues.push({
          label: getLegalHoldReasons(data.description),
          value: data.referenceCode,
        });
      }
    });
    return legalValues;
  }
}

export function convertDateFormat(inputDate: any) {
  const yearPart = inputDate.slice(0, 4);
  const monthDayPart = inputDate.slice(5);

  const month = monthDayPart.slice(0, 2);
  const day = monthDayPart.slice(3);

  return `${yearPart}-${month}-${day}`;
}

export async function manageLegalHold(
  customerId: any,
  payload: any,
  legalTableBody: any,
  update: any,
  setLoader: any,
  setLegalTableBody: any,
  setResponseStatus: any,
  setLegalCheck: any,
  setUnHoldPopup: any,
  setLegalHoldDetails: any,
  setCaterogy: any,
  category: any,
  setErrorMessage: any,
  setErrorMessagePopup: any,
  setLoaderCardMessage: any
) {
  setLoader(true);
  setLoaderCardMessage('Validating Customer Legal Details');
  const legalHoldId = legalTableBody.find(
    (obj: any) => obj.closedDate === null
  )?.legalHoldId;
  if (update) {
    payload.isManage = 1;
    payload.legalHoldId = legalHoldId.toString();
  }
  payload.isManage = 1;
  payload.customerId = parseInt(customerId);
  const legalHoldResponse = await GetLegalCustomer(payload);
  setLegalHoldDetails({});
  if (legalHoldResponse?.status == 200) {
    setLoaderCardMessage('Updating Customer Legal Details');
    const legalDetails = await GetLegalCustomer({
      customerId: parseInt(customerId),
      limit: 10,
      offset: 0,
    });
    getLegalData(legalDetails, setResponseStatus, setLegalTableBody, setLoader);
    const legalHoldStatus =
      legalDetails?.status == 200
        ? legalDetails?.data?.legalholdCustomers.find(
            (obj: any) => obj.closedDate === null
          )?.legalHoldId
        : null;
    let categoryArray = category.split(' - ');
    if (legalHoldStatus) {
      if (categoryArray.length == 1 && categoryArray[0] == '') {
        categoryArray = ['Legal'];
      } else if (!categoryArray.includes('Legal')) {
        categoryArray.push('Legal');
      }
      const updatedCategory = categoryArray.join(' - ');
      setCaterogy(updatedCategory);
      setLegalCheck(true);
    } else {
      categoryArray = categoryArray.filter(
        (item: any) => item.toLowerCase() !== 'legal'
      );
      const updatedCategory = categoryArray.join(' - ');
      setCaterogy(updatedCategory);
      setLegalCheck(false);
    }
    setUnHoldPopup(false);
    setLoader(false);
  } else {
    setUnHoldPopup(false);
    setErrorMessage('Unable to Hold/Unhold the Customer');
    setErrorMessagePopup(true);
    setLoader(false);
  }
}

export const getUserDetails = async () => {
  const userDetails: any = await getCurrentUser();
  const employeeId = userDetails?.data.employeeId;
  const email = userDetails?.data?.email;
  const userName = `${userDetails?.data.firstName} ${userDetails?.data.lastName}`;

  const req = {
    coworkerId: employeeId,
    menuRequired: true,
    companyCodeRequired: true,
  };

  const response = await getMenuConfigurationDetails(req);

  const responseData: any = {
    employeeId: employeeId,
    emailId: email,
    userName: userName,
    menuConfigArray: response?.data?.menuConfig,
  };

  return responseData;
};

export function getLegalHoldCustomerStatus(customerDetailsResponse: any) {
  const response =
    customerDetailsResponse?.legalCustomer?.data?.legalholdCustomers.find(
      (obj: any) => obj.closedDate === null
    );
  return response ? true : false;
}
