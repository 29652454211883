export enum AppRoute {
  Customer = '/operations/customer',
  AgreementSearch = '/operations/agreement',
  AgreementDetails = '/operations/agreement/details/:customerId/:agreementId/',
  Location = '/operations/location',
  Inventory = '/operations/inventory',
  CustomerDetails = '/operations/customer/details/:customerId',
  CustomerDetailsWithTab = '/operations/customer/details/:customerId/:tabName?',
  LocationSearch = '/operations/location',
  StoreDetails = '/operations/store/:storeId',
  LegalHoldSearch = '/operations/legal',
  InventoryDetails = '/operations/inventory/:inventoryNumber/:inventoryId/details',
  menuPermissions = '/operations/admin/menupermissions',
  appConfiguration = '/operations/admin/appconfigurations',
  resetPin = '/operations/admin/resetpin',
}
